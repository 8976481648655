/* stylelint-disable scss/at-import-partial-extension */
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/variables';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/media';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/button';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/loader';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/icons';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/parts';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/variables.override';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/button.override';

.ProductListWidget {
    &-Page {
        display: flex;
        align-items: center;
        padding: 0;
    }
}
