/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';

:root {
    --price-color: var(--primary-error-color);
    --price-with-discount-color: var(--primary-error-color);
    --price-discount-color: var(--body-content-secondary-color);
}

.ProductPrice {
    font-size: 20px;
    line-height: 1;

    @include desktop {
        font-size: 24px;
        min-height: unset;
    }

    &-PriceValue {
        line-height: 1;
        margin-block-start: 0;
        color: var(--price-color);
    }

    del {
        font-size: 14px;
        line-height: 15px;
        font-weight: 700;
        margin-inline-end: 8px;
        opacity: 1;

        @include desktop {
            font-size: 16px;
            line-height: 18px;
            margin-inline-end: 12px;
        }
    }

    &-HighPrice {
        color: var(--price-discount-color);
    }
}
