/* stylelint-disable scss/at-import-partial-extension */
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/variables';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/media';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/button';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/loader';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/icons';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/parts';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/variables.override';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/button.override';

.ProductActions {
    &-AddToCartFixed {
        inset-block-end: 0;

        .PDP & {
            @include mobile {
                transform: translateY(0);
                position: relative;
                z-index: unset;
            }
        }
    }
}
