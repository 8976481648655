/* stylelint-disable scss/at-import-partial-extension */
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/variables';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/media';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/button';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/loader';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/icons';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/parts';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/variables.override';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/button.override';

.ProductCarousel {
    &-Slider {
        padding-inline-start: 16px;

        .ProductCard {
            max-width: 230px;
            padding-inline-end: 16px;
        }
    }

    &-Carousel {
        width: 100%;
        max-width: var(--content-wrapper-width);

        .CarouselScroll {
            &-ContentWrapper {
                flex-direction: column;
            }

            &-Content {
                padding-inline-end: 0;
            }

            &Item {
                width: 280px;
                flex: unset;
                height: unset;
                border: none;
                filter: unset;
                flex-shrink: 0;

                &:first-child {
                    margin-inline-start: calc(var(--carousel-scroll-gap) / 2);
                }
            }
        }

        .ProductCard {
            width: 100%;
        }
    }
}
