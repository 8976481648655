/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';

.ProductGallery {
    &-SliderWrapper {
        .new-label {
            inset-inline-start: -23px;
            clip-path: polygon(20% 0%, 78% 0%, 100% 100%, 0% 100%);
            transform: rotate(316deg);
            inset-block-start: 19px;

            @include desktop {
                inset-inline-start: -37px;
                inset-block-start: 31px;
                width: 177px;
                height: 38px;
                transform: rotate(314deg);
            }

            span {
                inset-block-start: 2px;

                @include desktop {
                    font-size: 30px;
                    line-height: 37px;
                }
            }
        }
    }
}
